exports.blogs = {
    data:[
       // {
        //     blogId: 1,
        //     summary_image_path: "patrick-graduation.jpg",
        //     summary_image_alt_name: "Image Of Patrick Slaine",
        //     title: "Introduction to Me",
        //     author: "Patrick Slaine",
        //     publishedDate: "16/12/2023",
        //     updatedDate:"21/12/2023",
        //     keywords: "",
        //     summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rutrum tortor orci, vitae vestibulum purus volutpat in. Nullam sit amet nisi quis tellus tincidunt tincidunt in et lectus. Morbi lobortis posuere efficitur. Phasellus aliquet dapibus velit quis sollicitudin. Cras ornare turpis leo, eget tempor nunc congue nec. Sed dignissim neque non eros tempor imperdiet. Phasellus ultricies, lacus sed ultrices ultricies, elit urna congue quam, non laoreet leo turpis sit amet nisi. Nam fermentum non augue vel rutrum. Aliquam erat volutpat. Duis nulla leo, volutpat at cursus id, congue in tellus. Praesent condimentum tortor quis magna gravida finibus. Aliquam elit risus, volutpat at porta nec, commodo ac elit. Aenean tempus ipsum eget fermentum auctor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi accumsan blandit mi sit amet congue.",
        //     content:[
        //         {
        //             type:"header",
        //             value:"This is a Section Header"
        //         },
        //         {
        //             type:"paragraph",
        //             value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque rutrum tortor orci, vitae vestibulum purus volutpat in. Nullam sit amet nisi quis tellus tincidunt tincidunt in et lectus. Morbi lobortis posuere efficitur. Phasellus aliquet dapibus velit quis sollicitudin. Cras ornare turpis leo, eget tempor nunc congue nec. Sed dignissim neque non eros tempor imperdiet. Phasellus ultricies, lacus sed ultrices ultricies, elit urna congue quam, non laoreet leo turpis sit amet nisi. Nam fermentum non augue vel rutrum. Aliquam erat volutpat. Duis nulla leo, volutpat at cursus id, congue in tellus. Praesent condimentum tortor quis magna gravida finibus. Aliquam elit risus, volutpat at porta nec, commodo ac elit. Aenean tempus ipsum eget fermentum auctor. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi accumsan blandit mi sit amet congue."
        //         },
        //         {
        //             type: "image",
        //             value:"patrick-graduation.jpg",
        //             alt: "Patrick Slaine's Graduation"
        //         },
        //         {
        //             type:"bulletpoint",
        //             before:"Before the bulletpoints",
        //             content:[
        //                 {value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
        //             ]    
        //         },
        //         {
        //             type:"numbered",
        //             before:"Before the numbered points",
        //             content:[
        //                 {id:1,value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {id:2,value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {id:3,value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."},
        //                 {id:4,value:"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
        //             ]
        //         }
        //     ]     
        // }
    ]
};